import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Select from "react-dropdown-select";
import { ProjectActionTypes } from '../../redux/project/project.types';
import { DefinitionsActionTypes } from '../../redux/definitions/definitions.types';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import CheckBox from '../../components/checkbox/checkbox.component';

import {options} from '../../utils/options';
import {countries} from '../../utils/countries';
import {regions} from '../../utils/regions';
import '../../App.css';
import './step3.styles.scss';
class Step3 extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      letterCount:0,
      limit:200,
      freelance:false,
      country: '',
      region: ''

    };
  }
  componentDidMount(){
    this.props.setCurrent('step-3');
  } 

  handleLetters = (event) => {
    this.setState(()=>({
      ...this.state,
      letterCount: event.target.value.length
    }));
    
  }

  handleCheck = (target) => {
    let tmpCheck = false;
    if (target.checked === true){
      tmpCheck = true;
    }
    this.setState(()=>({
      ...this.state,
      freelance: tmpCheck
    }));

  }


  selectCountry (val) {
    this.setState({...this.state, country: val });
  }

  selectRegion (val) {
    this.setState({...this.state, region: val });
  }  

  render(){
    return(
      <section className="mainSection mainSection3">
        <div className="sectionWrapper">
          <div className="gridWrapper">
            <div>
              <h4 className="sectionTitle">Çalıştığınız Kurum</h4>
              <input type="text" placeholder="Lütfen tam adınını giriniz" className="edits" disabled={this.state.freelance} />
              <CheckBox value="bagimsiz"  onClick={(event) => this.handleCheck(event.target)}>Bağımsız Çalışıyorum</CheckBox>              
            </div>
            <div>
              <h4 className="sectionTitle">Kısa Özgeçmişiniz</h4>
              <textarea className="memos" placeholder={`Lütfen kısa özgeçmişinizi yazınız. Maksimum ${this.state.limit} karakter`} onChange={this.handleLetters}></textarea>
              <span className="letterCount"><span className={this.state.letterCount > this.state.limit ? " tooManyLetters" : ""}>{this.state.letterCount}</span> / {this.state.limit}</span>
            </div>
            <div>
              <h4 className="sectionTitle">Yaşadığınız Ülke / Şehir</h4>
              <div className="componentWrapper">
                <div className="contentInfoWrapper gorus-konular">
                  <CountryDropdown
                    value={this.state.country}
                    onChange={(val) => this.selectCountry(val)}
                    classes="react-dropdown-select customSelect"
                    defaultOptionLabel="Lütfen ülke seçiniz"
                    blankOptionLabel=" - "
                  />
                  <RegionDropdown
                    country={this.state.country}
                    value={this.state.region}
                    onChange={(val) => this.selectRegion(val)} 
                    classes="react-dropdown-select customSelect"
                    defaultOptionLabel="Lütfen şehir seçiniz."
                    blankOptionLabel=" - "
                  />

                 
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>

      
    );
  }
}

const mapStateToProps = state => {
  return {
    step3: state.definitions.step3,
    warningMessage: state.project.warningMessageText,
    warningType: state.project.warningMessageType,
    currentPage: state.project.currentPage
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onWarning: (payload) => dispatch({ type: ProjectActionTypes.SET_WARNING_MESSAGE, payload: payload}),
    setDefault: () => dispatch({ type: ProjectActionTypes.SET_DEFAULT}),
    setStep3: (step3) => dispatch({ type: DefinitionsActionTypes.SET_STEP3, step3: step3}),
    setCurrent: (value) => dispatch({type: ProjectActionTypes.SET_CURRENT, value: value })
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Step3));
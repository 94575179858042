import React from 'react';
import { Redirect } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ProjectActionTypes } from '../../redux/project/project.types';
import { DefinitionsActionTypes } from '../../redux/definitions/definitions.types';
//import ImageUploader from 'react-images-upload';

//import uploadButton from '../../assets/img/upload.svg';
//import removeButton from '../../assets/img/delete.svg';

import './step1.styles.scss';

class Step1 extends React.Component{

  constructor(props){
    super(props);
    this.state = { pictures:[]};
    this.onDrop = this.onDrop.bind(this);
  }

  onDrop(pictureFiles, pictureDataURLs) {
    this.setState({
        pictures: pictureFiles
    });
  }  

  componentDidMount(){
    this.props.setCurrent('step-1');
  }

  render(){
    return(
      <section className="mainSection">
        <div className="sectionWrapper">
          <p className="girisYazi">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. In feugiat, metus vitae finibus ultricies, lorem massa eleifend nisl, ac sodales mauris risus semper eros. Nunc eget felis commodo, rhoncus leo non, vulputate neque. Suspendisse eget dictum nibh, et rutrum turpis. Donec justo est, ornare sit amet rutrum a, scelerisque quis nulla.
          </p>
          <h4 className="sectionTitle">Fotoğrafınız</h4>
          <div className="imageFigure">
            <svg xmlns="http://www.w3.org/2000/svg" className="iconFemale" viewBox="0 0 50 50">
              <g data-name="1" id="_1">
                <rect fill="none"/>
                <path d="M30.933,32.528  c-0.026-0.287-0.045-0.748-0.06-1.226c4.345-0.445,7.393-1.487,7.393-2.701c-0.012-0.002-0.011-0.05-0.011-0.07  c-3.248-2.927,2.816-23.728-8.473-23.306c-0.709-0.6-1.95-1.133-3.73-1.133c-15.291,1.157-8.53,20.8-12.014,24.508  c-0.002,0.001-0.005,0.001-0.007,0.001c0,0.002,0.001,0.004,0.001,0.006c0,0.001-0.001,0.002-0.001,0.002s0.001,0,0.002,0.001  c0.014,1.189,2.959,2.212,7.178,2.668c-0.012,0.29-0.037,0.649-0.092,1.25C19.367,37.238,7.546,35.916,7,45h38  C44.455,35.916,32.685,37.238,30.933,32.528z" fill="none" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" />
              </g>
            </svg>          
          </div>
          <div className="imageToolbar">
            <button className="buttonUpload" type="button">
              
              Resim Yükle
            </button>                      
          {/*
            <img className="img-upload" src={uploadButton} alt="Upload"/>
            <button className="buttonTransparent " type="button">
              <img className="img-remove" src={removeButton} alt="Remove"/>
            </button>

            <button className="buttonTransparent " type="button">
              <img className="img-remove" src={removeButton} alt="Remove"/>
            </button>
            <button className="buttonTransparent" type="button">
              <img className="img-upload" src={uploadButton} alt="Upload"/>
            </button>            
          <ImageUploader
            withIcon={true}
            buttonText='Resim Yükle'
            onChange={this.onDrop}
            imgExtension={['.jpg', '.gif', '.png', '.gif']}
            maxFileSize={5242880}
          />          

            */
          }

          </div>
          <h4 className="sectionTitle titleAdsoyad">Adınız / Soyadınız</h4>
          <input type="text" placeholder="Lütfen tam adınızı giriniz" className="edits" />
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    step1: state.definitions.step1,
    warningMessage: state.project.warningMessageText,
    warningType: state.project.warningMessageType,
    currentPage: state.project.currentPage
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onWarning: (payload) => dispatch({ type: ProjectActionTypes.SET_WARNING_MESSAGE, payload: payload}),
    setDefault: () => dispatch({ type: ProjectActionTypes.SET_DEFAULT}),
    setStep1: (step1) => dispatch({ type: DefinitionsActionTypes.SET_STEP1, step1: step1}),
    setCurrent: (value) => dispatch({type: ProjectActionTypes.SET_CURRENT, value: value })
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Step1));
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ProjectActionTypes } from '../../redux/project/project.types';
import { DefinitionsActionTypes } from '../../redux/definitions/definitions.types';
import '../../App.css';
import './step4.styles.scss';
import RadioButton from '../../components/radiobutton/radiobutton.component';
import Button from '../../components/button/button.component';

class Step4 extends React.Component{

  constructor(props){
    super(props);
    this.state = {};
  }
  componentDidMount(){
    this.props.setCurrent('step-4');
  } 
  render(){
    return(
      <section className="mainSection">
        <div className="sectionWrapper">
          <div className="gridWrapper">
            <div>
              <h4 className="sectionTitle">Medya deneyiminiz var mı?</h4>
              <div className="medyaDeneyimiRadioWrapper">
                <div name="medyaDeneyimi" className="medyaDeneyimiRadioGroup">
                  <RadioButton name="checkMedyaDeneyimi" value="1">Var</RadioButton>
                  <RadioButton name="checkMedyaDeneyimi" value="0" checked="checked">Yok</RadioButton>
                </div>              
              </div>
            </div>
            <div>
              <h4 className="sectionTitle">Konferans deneyiminiz var mı?</h4>
              <div className="konferansDeneyimiRadioWrapper">
                <div name="konferansDeneyimi" className="medyaDeneyimiRadioGroup">
                  <RadioButton name="checkKonferansDeneyimi" value="1">Var</RadioButton>
                  <RadioButton name="checkKonferansDeneyimi" value="0" checked="checked">Yok</RadioButton>
                </div>              

              </div>
            </div>
            <div>
              <h4 className="sectionTitle">İlgili yayın ve referans bağlantıları</h4>
              <sub className="linkInfo">Buraya yayın, youtube bağlantısı ekleyebilirsiniz.</sub>
              <div className="baglantiWrapper">
                <input type="text" placeholder="Bağlantı adresi girin" className="edits" />
                <Button>Ekle</Button>
              </div>
              <div className="componentWrapper">
                <div className="contentInfoWrapper gorus-konular step4GorusKonular">
                 
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>
      
    );
  }
}

const mapStateToProps = state => {
  return {
    step4: state.definitions.step4,
    warningMessage: state.project.warningMessageText,
    warningType: state.project.warningMessageType,
    currentPage: state.project.currentPage
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onWarning: (payload) => dispatch({ type: ProjectActionTypes.SET_WARNING_MESSAGE, payload: payload}),
    setDefault: () => dispatch({ type: ProjectActionTypes.SET_DEFAULT}),
    setStep4: (step4) => dispatch({ type: DefinitionsActionTypes.SET_STEP4, step4: step4}),
    setCurrent: (value) => dispatch({type: ProjectActionTypes.SET_CURRENT, value: value })
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Step4));
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ProjectActionTypes } from '../../redux/project/project.types';
import { DefinitionsActionTypes } from '../../redux/definitions/definitions.types';
import { SocialIcon } from 'react-social-icons';

import CheckBox from '../../components/checkbox/checkbox.component';
import RadioButton from '../../components/radiobutton/radiobutton.component';
import Button from '../../components/button/button.component';
import './step5.styles.scss';

class Step5 extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      showInfo:true,
      disablePhone:true,
      disableEmail:true
    };
  }

  handleIsContactShow = (val) => {
    let tmpCheck = false;
    if (val === "1"){
      tmpCheck = true;
    }
    this.setState(()=>({
      ...this.state,
      showInfo: tmpCheck
    }));
  }  

  handleCheck = (target) => {
    let tmpCheck = true;
    if (target.checked === true){
      tmpCheck = false;
    }
    
    if (target.name === "contactPhone"){
      this.setState(()=>({
        ...this.state,
        disablePhone: tmpCheck
      }));

      if (tmpCheck === false){
        setTimeout(()=> {
          this.phoneInput.focus();
        },50);
        
      }
  
  
    }else if (target.name === "contactEmail"){
      this.setState(()=>({
        ...this.state,
        disableEmail: tmpCheck
      }));
      if (tmpCheck === false){
        setTimeout(()=> {
          this.emailInput.focus();
        },50);        
        
      }

    }


  }  

  componentDidMount(){
    this.props.setCurrent('step-5');
  }   
  render(){
    return(
      <section className="mainSection">
        <div className="sectionWrapper">
          <div className="gridWrapper gridWrapperStep5">
            <div>
              <h4 className="sectionTitle">Sosyal Medya Hesaplarınız</h4>
              <div className="baglantiWrapper">
                <input type="text" placeholder="Bağlantı adresi girin" className="edits" />
                <Button>Ekle</Button>
              </div>

              <div className="componentWrapper">
                <div className="contentInfoWrapper gorus-konular step5SosyalMedya">

                  <div className="socialLine"><SocialIcon className="socialMediaIcon" style={{ height: 30, width: 30 }} network="twitter" url="#" /> <span className="socialMedyaInfo">Twitter hesabı adresi</span></div> 
                  <div className="socialLine"><SocialIcon className="socialMediaIcon" style={{ height: 30, width: 30 }} network="linkedin" url="#" /> <span className="socialMedyaInfo">Linkedin hesabı adresi</span></div>
                  <div className="socialLine"><SocialIcon className="socialMediaIcon" style={{ height: 30, width: 30 }} network="youtube" url="#" /> <span className="socialMedyaInfo">Youtube hesabı adresi</span></div>
                  <div className="socialLine"><SocialIcon className="socialMediaIcon" style={{ height: 30, width: 30 }} network="facebook" url="#"  /> <span className="socialMedyaInfo">Facebook hesabı adresi</span></div>

                </div>
              </div>


            </div>
            <div>
              <h4 className="sectionTitle">İletişim bilgilerinizin profilinizde görünmesini ister misiniz?</h4>
              <div className="medyaDeneyimiRadioGroupWrapper">
                <div name="medyaDeneyimi" className="medyaDeneyimiRadioGroup">
                  <RadioButton name="checkMedyaDeneyimi" value="1" onClick={()=> this.handleIsContactShow('1')} checked={this.state.showInfo === true ? true : false} >Evet</RadioButton>
                  <RadioButton name="checkMedyaDeneyimi" value="0" onClick={()=> this.handleIsContactShow('0')} checked={this.state.showInfo === false ? true : false}>Hayır</RadioButton>
                </div>              
              </div>

              {
                this.state.showInfo === true ?
                <>
                  <h4 className="sectionTitle">Profilinizde gözükmesini istediğiniz bilgileri işaretleyiniz.</h4>
                  <div className="medyaDeneyimiRadioGroupWrapper">
                    <div name="medyaDeneyimi" className="medyaDeneyimiRadioGroup">
                      <div value="0" name="iletisimBilgileri" class="iletisimBilgileriCheckGroupPres">
                        <div className="contactWrapper">
                          <CheckBox value="" className="contactCheck" name="contactPhone" onClick={(event) => this.handleCheck(event.target)}>Telefon :</CheckBox> <input type="text" ref={(input) => { this.phoneInput = input; }} placeholder="Telefon numaranızı giriniz" className="edits" disabled={this.state.disablePhone} />
                        </div>
                        <div className="contactWrapper">
                          <CheckBox value="" className="contactCheck" name="contactEmail" onClick={(event) => this.handleCheck(event.target)}>E-posta :</CheckBox> <input type="text" ref={(input) => { this.emailInput = input; }} placeholder="E-Posta adresinizi giriniz" className="edits" disabled={this.state.disableEmail} />
                        </div>
                            </div>                     
                          </div>              
                        </div>

        
                  <h4 className="sectionTitle sectionBottom"> Lütfen iletişim tercihlerinizi belirtiniz.</h4>
                  <div className="contactWrapper contactTercih">
                    <CheckBox value="" className="contactCheck" name="contactPhone1">Telefon </CheckBox> 
                    <CheckBox value="" className="contactCheck" name="contactEmail1">E-posta </CheckBox> 

                  </div>
                </>:
                <>
                  <p className="infoAltyapi">
                    Size telefon numaranız ve e-posta adresiniz sitede gözükmeden ulaşılabilecek bir alt yapımız mevcut.Bunun için aşağıdaki tercihlerden birini seçiniz.
                    <span className="altyapiSub">
                      Size ulaşmak isteyen medya çalışanı telefon numaranızı görmeden sizi arayabilecek veya sms veya whatsapp gönderebilecek, e-mailler ise yine bizim platformumuz üzerinden sizin e-maillerinize düşecek. Doldurduğunuz formun iletişim bilgileri, sitede arama yapanlar tarafından görünmeyecek                    
                    </span>
                  </p>
                  <div className="contactWrapper groupContacts">
                  <div value="0" name="iletisimBilgileri" class="iletisimBilgileriCheckGroup">
                    <CheckBox value="1">Sms</CheckBox>
                    <CheckBox value="2">Arama</CheckBox>
                    <CheckBox value="3">WhatsApp</CheckBox>
                  </div> 
                  <input type="text" placeholder="Telefon numaranızı giriniz" className="edits"  />
                    
                  </div>
                  <div className="contactWrapper groupContacts2">
                    <CheckBox value="" class="contactCheck" name="contactEmail">E-posta :</CheckBox> <input type="text" placeholder="E-Posta adresinizi giriniz" className="edits" />
                  </div>                  
                </>
                 
              }


              <div className="margin-top-x">
                <CheckBox value="rizametni" class="onayTick">Mesafeli Satış Sözleşmesi'ni ve Ön Bilgilendirme Formu'nu okudum onaylıyorum.</CheckBox>
              </div>



            </div>

          </div>
        </div>
      </section>
      
      
    );
  }
}

const mapStateToProps = state => {
  return {
    step5: state.definitions.step5,
    warningMessage: state.project.warningMessageText,
    warningType: state.project.warningMessageType,
    currentPage: state.project.currentPage
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onWarning: (payload) => dispatch({ type: ProjectActionTypes.SET_WARNING_MESSAGE, payload: payload}),
    setDefault: () => dispatch({ type: ProjectActionTypes.SET_DEFAULT}),
    setstep5: (step5) => dispatch({ type: DefinitionsActionTypes.SET_step5, step5: step5}),
    setCurrent: (value) => dispatch({type: ProjectActionTypes.SET_CURRENT, value: value })
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Step5));
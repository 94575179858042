import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Select from "react-dropdown-select";
import CheckBox from '../../components/checkbox/checkbox.component';

import { ProjectActionTypes } from '../../redux/project/project.types';
import { DefinitionsActionTypes } from '../../redux/definitions/definitions.types';

import {options} from '../../utils/options';
import {countries} from '../../utils/countries';
import {regions} from '../../utils/regions';

import '../../App.css';
import './step2.styles.scss';

class Step2 extends React.Component{
  constructor(props){
    super(props);
    this.state = {};
  }
  componentDidMount(){
    this.props.setCurrent('step-2');
  } 
  render(){
    return(
      <section className="mainSection mainSection2">
        <div className="sectionWrapper">
          <div className="gridWrapper">
            <div>
              <h4 className="sectionTitle">Uzmanlık Alanlarınız</h4>
              <div className="componentWrapper">
                  <div className="contentInfoWrapper">
                    <CheckBox value="communication" >Communication</CheckBox>
                    <CheckBox value="climate-and-environment" >Climate and Environment</CheckBox>
                    <CheckBox value="life-and-medical-sciences" >Life and Medical Sciences</CheckBox>
                    <CheckBox value="engineering" >Engineering</CheckBox>
                    <CheckBox value="gender" >Gender</CheckBox>
                    <CheckBox value="civil-society-and-activism" >Civil Society and Activism</CheckBox>
                    <CheckBox value="health" >Health</CheckBox>
                    <CheckBox value="research-and-innovation" >Research and Innovation</CheckBox>
                    <CheckBox value="digital-economy-and-society" >Digital Economy and Society</CheckBox>
                    <CheckBox value="media" >Media</CheckBox>
                    <CheckBox value="artificial-intelligence" >Artificial Intelligence</CheckBox>
                    <CheckBox value="arts-and-culture" >Arts and Culture</CheckBox>
                    <CheckBox value="democracy-and-rule-of-law" >Democracy and Rule of Law</CheckBox>
                    <CheckBox value="economics" >Economics</CheckBox>
                    <CheckBox value="energy" >Energy</CheckBox>
                    <CheckBox value="justice-and-fundamental-rights" >Justice and Fundamental Rights</CheckBox>
                    <CheckBox value="law" >Law</CheckBox>
                    <CheckBox value="business-and-management" >Business and Management</CheckBox>
                    <CheckBox value="education-and-skills" >Education and Skills</CheckBox>
                    <CheckBox value="foreign-affairs-and-international-relations" >Foreign Affairs and International Relations</CheckBox>
                    <CheckBox value="cybersecurity" >Cybersecurity</CheckBox>
                    <CheckBox value="history" >History</CheckBox>
                    <CheckBox value="air-and-space" >Air and Space</CheckBox>
                    <CheckBox value="employment-and-social-affairs" >Employment and Social Affairs</CheckBox>
                    <CheckBox value="entrepreneurship-start-ups-and-smes" >Entrepreneurship, Start-ups and SMEs</CheckBox>
                    <CheckBox value="migration-and-asylum" >Migration and Asylum</CheckBox>
                    <CheckBox value="eu-affairs" >EU Affairs</CheckBox>
                    <CheckBox value="agriculture-and-food" >Agriculture and Food</CheckBox>
                    <CheckBox value="banking-and-financial-services" >Banking and Financial Services</CheckBox>
                    <CheckBox value="cities-and-regions" >Cities and Regions</CheckBox>
                    <CheckBox value="humanitarian-aid-and-civil-protection" >Humanitarian Aid and Civil Protection</CheckBox>
                    <CheckBox value="trade" >Trade</CheckBox>
                    <CheckBox value="defence" >Defence</CheckBox>
                    <CheckBox value="international-cooperation-and-development" >International Cooperation and Development</CheckBox>
                    <CheckBox value="security" >Security</CheckBox>
                    <CheckBox value="internal-market-and-industry" >Internal Market and Industry</CheckBox>
                    <CheckBox value="competition" >Competition</CheckBox>
                    <CheckBox value="intellectual-property-and-data-protection" >Intellectual Property and Data Protection</CheckBox>
                    <CheckBox value="mobility-and-transport" >Mobility and Transport</CheckBox>
                    <CheckBox value="maritime-affairs-and-fisheries" >Maritime Affairs and Fisheries</CheckBox>
                    </div>
              </div>
            </div>
            <div>
              <h4 className="sectionTitle">Görüş Vermek İstediğiniz Konular</h4>
              <div className="componentWrapper">
                <div className="contentInfoWrapper gorus-konular">

                  <Select
                    multi
                    create
                    placeholder="Lütfen konu başlığı seçiniz."
                    addPlaceholder="Birden fazla seçebilirsiniz."
                    createNewLabel="Ekle"
                    onCreateNew={(item) => console.log('%c New item created ', 'background: #555; color: tomato', item)}
                    options={options}
                    labelField="name"
                    valueField="email"
                    color="#80bd01"
                    onChange={(value) =>
                      console.log(`%c > onChange berat `, 'background: #555; color: tomato', value)
                    }
                 />


                </div>

              </div>
            </div>
            <div>
              <h4 className="sectionTitle">Uzman Olduğunuz Ülkeler/Bölgeler</h4>
              <div className="componentWrapper">
                <div className="contentInfoWrapper gorus-konular">

                  <Select
                    multi
                    create
                    placeholder="Lütfen ülke seçiniz."
                    addPlaceholder="Birden fazla seçebilirsiniz."
                    createNewLabel="Ekle"
                    onCreateNew={(item) => console.log('%c New item created ', 'background: #555; color: tomato', item)}
                    options={countries}
                    labelField="name"
                    valueField="name"
                    color="#80bd01"
                    onChange={(value) =>
                      console.log(`%c > onChange berat `, 'background: #555; color: tomato', value)
                    }
                  />
                  <Select
                    multi
                    create
                    placeholder="Lütfen bölge seçiniz."
                    addPlaceholder="Birden fazla seçebilirsiniz."
                    createNewLabel="Ekle"
                    onCreateNew={(item) => console.log('%c New item created ', 'background: #555; color: tomato', item)}
                    options={regions}
                    labelField="name"
                    valueField="name"
                    color="#80bd01"
                    onChange={(value) =>
                      console.log(`%c > onChange berat `, 'background: #555; color: tomato', value)
                    }
                  />
                 
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>
      
    );
  }
}

const mapStateToProps = state => {
  return {
    step2: state.definitions.step2,
    warningMessage: state.project.warningMessageText,
    warningType: state.project.warningMessageType,
    currentPage: state.project.currentPage
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onWarning: (payload) => dispatch({ type: ProjectActionTypes.SET_WARNING_MESSAGE, payload: payload}),
    setDefault: () => dispatch({ type: ProjectActionTypes.SET_DEFAULT}),
    setStep2: (step2) => dispatch({ type: DefinitionsActionTypes.SET_STEP2, step2: step2}),
    setCurrent: (value) => dispatch({type: ProjectActionTypes.SET_CURRENT, value: value })
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Step2));
import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import './header.styles.scss';

class Header extends React.Component{

  constructor(props){
    super(props);
    this.state = {};
  }

  goTo = (event,url) => {
    event.preventDefault();
    return this.props.history.push('/'+url);    
  }

  componentDidMount(){

  }  

  render(){
    return(
      <header>
      <h1>Katılımcı <span className="titleColor">Bilgi Formu</span></h1>
      <nav className="navbar">
        <div className={this.props.currentPage === 'step-1' ? 'nav-active' : ''}>
          <Link 
            to="/step-1"
            className='nav-links'
            
          >
            1.Adım
          </Link>              
        </div>
        <div className={this.props.currentPage === 'step-2' ? 'nav-active' : ''}>
          <Link 
            to="/step-2"
            className='nav-links'
            
          >
            2.Adım
          </Link> 
        </div>
        <div className={this.props.currentPage === 'step-3' ? 'nav-active' : ''}>
          <Link 
            to="/step-3"
            className='nav-links'
            
          >
            3.Adım
          </Link> 
        </div>
        <div className={this.props.currentPage === 'step-4' ? 'nav-active' : ''}>
          <Link 
            to="/step-4"
            className='nav-links'
            
          >
            4.Adım
          </Link> 
        </div>
        <div className={this.props.currentPage === 'step-5' ? 'nav-active' : ''}>
          <Link 
            to="/step-5"
            className='nav-links'
          >
            5.Adım
          </Link>
        </div>
      </nav>
    </header>      
    );
  }
}

export default withRouter(Header);
import React from 'react';
import { 
  FASTDesignSystemProvider,
} from '@microsoft/fast-components';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';

//import { ProjectActionTypes } from './redux/project/project.types';
import Header from './components/header/header.component';
import Footer from './components/footer/footer.component';
import Step1 from './pages/step1/step1.component';
import Step2 from './pages/step2/step2.component';
import Step3 from './pages/step3/step3.component';
import Step4 from './pages/step4/step4.component';
import Step5 from './pages/step5/step5.component';
import './App.css';
import './host.css';

class App extends React.Component {
  

  
  render(){
    return (
      <fast-design-system-provider use-defaults>      
      <main>
        <Router>
          <Header currentPage={this.props.currentPage} />
          <Switch>
            <Route path="/" exact component={Step1} />
            <Route path="/step-1" exact component={Step1} />
            <Route path="/step-2" exact component={Step2} />
            <Route path="/step-3" exact component={Step3} />
            <Route path="/step-4" exact component={Step4} />
            <Route path="/step-5" exact component={Step5} />
          </Switch>
          <Footer currentPage={this.props.currentPage} />
        </Router>
      </main>
      </fast-design-system-provider>
    );
  }
}

const mapStateToProps = state => {
  return {
    warningMessage: state.project.warningMessageText,
    warningType: state.project.warningMessageType,
    currentPage: state.project.currentPage,
    pageload: state.project.pageload    
  }
}

export default connect(mapStateToProps)(App);


import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import sessionStorage from 'redux-persist/lib/storage/session';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import projectReducer from './project/project.reducer';
import definitionsReducer from './definitions/definitions.reducer';

const persistConfig = {
  key: 'root',
  storage: storage,
  stateReconciler: autoMergeLevel2  
}

const definitionsPersistConfig = {
  key: 'definitions',
  storage: sessionStorage
}

const rootReducer = combineReducers({
  project: projectReducer,
  definitions: persistReducer(definitionsPersistConfig,definitionsReducer)
});


export default persistReducer(persistConfig,rootReducer);
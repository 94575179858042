import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ProjectActionTypes } from '../../redux/project/project.types';
import { DefinitionsActionTypes } from '../../redux/definitions/definitions.types';

import './footer.styles.scss';


class Footer extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      buttonLabel: "İleri",
    };
  }

  goTo = (event,type) => {
    event.preventDefault();
    let step = parseInt(this.props.currentPage.charAt(this.props.currentPage.length-1));
    //this.props.setLoading(true);
    if (type === "forward"){
      step = step+1;
      if (step >= 5){
        this.props.setCurrent('step-5');
        return this.props.history.push('/step-5');    
      } else{
        this.props.setCurrent('step-'+step);
        return this.props.history.push('/step-'+step);    
      }   
    }else{

      step = step-1;
      if (step === 0){
        this.props.setCurrent('step-1');
        return this.props.history.push('/step-1');    
      }else{
        this.props.setCurrent('step-'+step);
        return this.props.history.push('/step-'+step);    
      }

    }
  }  

  componentDidMount(){
    let step = parseInt(this.props.currentPage.charAt(this.props.currentPage.length-1));
    
    if (step === 5){
      this.setState({...this.state,buttonLabel: 'Tamamla'});
    }else{
      this.setState({...this.state,buttonLabel: 'İleri'});
    }

  }

  render(){
    return(
      <footer>
          <Link className="backButtonLinkWrapper" onClick={(event) => this.goTo(event,'backward')}>
            {
              this.props.currentPage !== "step-1" && 
                <svg className="backButton" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><title/><g data-name="1" id="_1"><path d="M353,450a15,15,0,0,1-10.61-4.39L157.5,260.71a15,15,0,0,1,0-21.21L342.39,54.6a15,15,0,1,1,21.22,21.21L189.32,250.1,363.61,424.39A15,15,0,0,1,353,450Z"/></g></svg>  
            }
            {
              this.props.currentPage !== "step-1" && 
              <h3 className="backButtonText">Geri</h3>
            }
          </Link>

          <Link className="nextButtonLinkWrapper" onClick={(event) => this.goTo(event,'forward')}>
            <h3 className={`nextButtonText ${this.props.currentPage === "step-5" ? "nextButtonTextDone" : ""}`}>{this.props.currentPage === "step-5" ? "Tamamla" : "İleri"}</h3>
            {
              this.props.currentPage === "step-5" ?
                <svg className="nextButtonDone" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg"><title/><path d="M64,0a64,64,0,1,0,64,64A64.07,64.07,0,0,0,64,0Zm0,122a58,58,0,1,1,58-58A58.07,58.07,0,0,1,64,122Z"/><path d="M87.9,42.36,50.42,79.22,40.17,68.43a3,3,0,0,0-4.35,4.13l12.35,13a3,3,0,0,0,2.12.93h.05a3,3,0,0,0,2.1-.86l39.65-39a3,3,0,1,0-4.21-4.28Z"/></svg>
                :
                <svg className="nextButton" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><title/><g data-name="1" id="_1"><path d="M202.1,450a15,15,0,0,1-10.6-25.61L365.79,250.1,191.5,75.81A15,15,0,0,1,212.71,54.6l184.9,184.9a15,15,0,0,1,0,21.21l-184.9,184.9A15,15,0,0,1,202.1,450Z"/></g></svg>
            }
            
          </Link>
      </footer>      
    );
  }
}

const mapStateToProps = state => {
  return {
    warningMessage: state.project.warningMessageText,
    warningType: state.project.warningMessageType,
    currentPage: state.project.currentPage
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onWarning: (payload) => dispatch({ type: ProjectActionTypes.SET_WARNING_MESSAGE, payload: payload}),
    setCurrent: (value) => dispatch({type: ProjectActionTypes.SET_CURRENT, value: value })
  }
}


export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Footer));